import React, { useState } from 'react';
import LogoV2 from '../assets/LogoHSV2.png';
import './css/RegistrationForm.css';

const NewPassword = () => {
  const [formState, setFormState] = useState({
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null); // Pour afficher la réponse API ou un message

  const validateForm = () => {
    let formErrors = {};

    if (!formState.password) {
      formErrors.password = "Le mot de passe est requis";
    } else if (formState.password.length < 6) {
      formErrors.password = "Le mot de passe doit contenir au moins 6 caractères";
    }

    if (!formState.confirmPassword) {
      formErrors.confirmPassword = "Veuillez confirmer votre mot de passe";
    } else if (formState.password !== formState.confirmPassword) {
      formErrors.confirmPassword = "Les mots de passe ne correspondent pas";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(`https://dev1.yokolong.org/password/reset`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'azerty',
          },
          body: JSON.stringify({
            password: formState.password, // Le nouveau mot de passe
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setApiResponse('Mot de passe mis à jour avec succès!');
          console.log('API Response:', data);
        } else {
          const errorData = await response.json();
          setApiResponse('Erreur lors de la mise à jour du mot de passe');
          console.error('Erreur API:', errorData);
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi à l\'API:', error);
        setApiResponse('Une erreur s\'est produite.');
      }
    } else {
      console.log('Erreur dans le formulaire', errors);
    }
  };

  return (
    <div className="inscription-form">
      <div className="logo-container">
        <img src={LogoV2} alt="Logo" />
      </div>
      <h2 className="title">Nouveau Mot de Passe</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">Nouveau Mot de Passe</label>
          <input
            type="password"
            id="password"
            value={formState.password}
            onChange={handleChange}
            placeholder="Entrez votre nouveau mot de passe"
          />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirmer le Mot de Passe</label>
          <input
            type="password"
            id="confirmPassword"
            value={formState.confirmPassword}
            onChange={handleChange}
            placeholder="Confirmez votre mot de passe"
          />
          {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
        </div>
        <button className="submitButton" type="submit">Mettre à jour</button>
      </form>
      {apiResponse && <p className="apiResponse">{apiResponse}</p>} {/* Affiche la réponse API */}
    </div>
  );
};

export default NewPassword;
