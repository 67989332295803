import React, { useState } from 'react';
import LogoV2 from '../assets/LogoHSV2.png';
import './css/RegistrationForm.css';

const LostPassword = () => {
  const [formState, setFormState] = useState({
    email: '',
    licence: '',
  });

  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null); // Pour afficher la réponse API ou un message

  const validateForm = () => {
    let formErrors = {};
    
    if (!formState.email || !/\S+@\S+\.\S+/.test(formState.email)) {
      formErrors.email = "Email invalide";
    }
    if (!formState.licence) {
      formErrors.licence = "Le numéro de licence est requis";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(`https://dev1.yokolong.org/password/forgot`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'azerty',
          },
          body: JSON.stringify({
            email: formState.email, // Utilise l'email saisi
            licence: formState.licence, // Utilise le numéro de licence saisi
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setApiResponse('Email de récupération envoyé avec succès!');
          console.log('API Response:', data);
        } else {
          const errorData = await response.json();
          setApiResponse('Erreur lors de l\'envoi de l\'email de récupération');
          console.error('Erreur API:', errorData);
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi à l\'API:', error);
        setApiResponse('Une erreur s\'est produite.');
      }
    } else {
      console.log('Erreur dans le formulaire', errors);
    }
  };

  return (
    <div className="inscription-form">
      <div className="logo-container">
        <img src={LogoV2} alt="Logo" />
      </div>
      <h2 className="title">MOT DE PASSE OUBLIÉ</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={formState.email}
            onChange={handleChange}
            placeholder="Entrez votre email"
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="licence">Numéro de licence</label>
          <input
            type="text"
            id="licence"
            value={formState.licence}
            onChange={handleChange}
            placeholder="Entrez votre numéro de licence"
          />
          {errors.licence && <span className="error">{errors.licence}</span>}
        </div>
        <button className="submitButton" type="submit">Envoyer</button>
      </form>
      {apiResponse && <p className="apiResponse">{apiResponse}</p>} {/* Affiche la réponse API */}
    </div>
  );
};

export default LostPassword;
